import React from "react"
import SEO from "../components/seo"
import { Link } from "gatsby"

const NotFoundPage = () => (
  <>
    <SEO title="404 Page Not Found" />
    <div className="hero-section is-404">
      <div className="cover-overlay"></div>
      <div className="overlay-text">
        <h3 className="is-white font-size-42 font-weight-bold">404 Ooops...</h3>
        <p className="is-white font-size-20">Looks like the page you are looking for is not here...</p>
        <div className="d-flex justify-content-center">
          <Link className="btn btn-secondary p-3" to="/" style={{width: '200px'}}>Take me home</Link>
        </div>

      </div>
    </div>
  </>
)

export default NotFoundPage
